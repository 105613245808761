<template>
  <div class="check" >
    <div class="pc_dialog">
        <div class="dialog flex_center">
            <div class="dialog_container">
                <div class="dialog_thumb">
                    <div class="dialog_group">
                        <div class="dialog_title">{{ $t('key49') }}</div>
                        <div class="dialog_content">
                          <!-- <div class="payment-simple">
                              <StripeElements
                                :stripe-key="stripeKey"
                                :instance-options="instanceOptions"
                                :elements-options="elementsOptions"
                                #default="{ elements }"
                                ref="elms"
                              >
                                <StripeElement
                                  type="card"
                                  :elements="elements"
                                  :options="cardOptions"
                                  ref="card"
                                />
                              </StripeElements>
                              <button @click="pay" type="button">支付</button>
                            </div> -->
                          <form id="payment-form" v-if="channelTag=='STRIPE_PAY'">
                            <div>
                                <label for="card-number" class="card_label">{{$t('key64')}}:</label>
                                <div id="card-number" class="card_value"></div>
                            </div>

                            <div>
                                <label for="card-expiry" class="card_label">{{$t('key66')}}:</label>
                                <div id="card-expiry" class="card_value"></div>
                            </div>
                            <div>
                                <label for="card-cvc" class="card_label">CVC:</label>
                                <div id="card-cvc" class="card_value"></div>
                            </div>
                            <!-- <div class="dialog_btn" @click="toPayment()" id="payment-form" type="submit"><div>{{ $t('key79') }}</div></div> -->
                        </form>
                        <!-- <form id="payment-form">
                          <div>
                              <label for="card-number">Card Number:</label>
                              <div id="card-number"></div>
                          </div>

                          <div>
                              <label for="card-expiry">Expiration Date:</label>
                              <div id="card-expiry"></div>
                          </div>
                          <div>
                              <label for="card-cvc">CVC:</label>
                              <div id="card-cvc"></div>
                          </div>
                          <button id="pay-button3" type="submit">Pay $10.00</button>
                      </form> -->
                          <el-form label-position="top" label-width="80px" :model="payForm" ref="paymentForm" :rules="payRules" v-if="channelTag!='STRIPE_PAY'">
                            <div class="input_thumb grid">
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key64')" prop="cardNo">
                                  <el-input v-model="payForm.cardNo" placeholder="Enter card number" ></el-input>
                                </el-form-item> 
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key65')" prop="cvv">
                                  <el-input v-model="payForm.cvv" placeholder="CVV"></el-input>
                                </el-form-item> 
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key66')" prop="year">
                                  <el-select v-model="payForm.year" placeholder="Year">
                                    <el-option
                                      v-for="item in yearOptions"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key66')" prop="month">
                                  <el-select v-model="payForm.month" placeholder="Month">
                                    <el-option
                                      v-for="item in monthOptions"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key69')" prop="firstName">
                                  <el-input v-model="payForm.firstName" placeholder="Enter you first name"></el-input>
                                </el-form-item> 
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key70')" prop="lastName">
                                  <el-input v-model="payForm.lastName" placeholder="Enter you last name"></el-input>
                                </el-form-item> 
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key71')" prop="country">
                                  <el-select v-model="payForm.country" placeholder="Select Country" @change="getRegion($event)">
                                    <el-option
                                      v-for="item in countryOptions"
                                      :key="item.iso_code_2"
                                      :label="item.name"
                                      :value="item.iso_code_2">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key80')" prop="state">
                                  <el-select v-model="payForm.state" placeholder="Select State">
                                    <el-option
                                      v-for="item in stateOptions"
                                      :key="item.code"
                                      :label="item.name"
                                      :value="item.code">
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key74')" prop="city">
                                  <el-input v-model="payForm.city" placeholder="Enter city"></el-input>
                                </el-form-item> 
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key76')" prop="address">
                                  <el-input v-model="payForm.address" placeholder="Enter address"></el-input>
                                </el-form-item> 
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key75')" prop="zip">
                                  <el-input v-model="payForm.zip" placeholder="Enter zip code"></el-input>
                                </el-form-item> 
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key77')" prop="phone">
                                  <el-input v-model="payForm.phone" placeholder="Enter phone"></el-input>
                                </el-form-item> 
                              </div>
                              <div class="input_group grid_md_6 grid_xs_12">
                                <el-form-item :label="$t('key78')" prop="email">
                                  <el-input v-model="payForm.email" placeholder="Enter email"></el-input>
                                </el-form-item> 
                              </div>
                              
                            </div>
                           
                           
                            <!-- <el-form-item label="Server Region " prop="region">
                              <el-select v-model="payForm.regio" placeholder="">
                                <el-option
                                  v-for="item in regionOptions"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                            </el-form-item> -->
                         </el-form>
                        </div>
                    </div>
                    <div class="pay_bottom">
                      <div class="pay_tips">{{ $t('key50') }}</div>
                      <div class="dialog_btn" @click="toPayment()"><div>{{ $t('key79') }}</div></div>
                    </div>
                    <div class="btn_close iconfont icon-close" @click="closeDialog()"></div>
                </div>
            </div>
        </div>
    </div>
    <remote-js src="https://pv.sohu.com/cityjson?ie=utf-8"></remote-js>
  </div>
</template>

<script>
 

import global from '../../utils/global';
// import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'


 import axios from 'axios';
export default {
  components:{
    'remote-js': {
        render (createElement) {
            return createElement('script', { attrs: { type: 'text/javascript', src: this.src } });
        },
        props: {
            src: { type: String, required: true },
        },
      },
      // StripeElements, StripeElement
    
  },
  props:{
    showDialog:{
      type:Boolean,
      default:false,
    },
    orderId:'',
    channelTag:'',
    shopName:'',
    stripeKey:'',
  },
  data(){
    return{
      stripePublicKey: 'pk_test_51QRl2uKGPnL9JpEdWzz05CFSMJSapzzXuHPE7lSvP9TzjLI1Yhn4avRzc9i4jC0PvgheundQhiOoiWr8wk9IyRSI00Krv9mDIp',
      stripeOptions: {
        // 这里可以添加 Stripe Elements 的选项
      },
  
      instanceOptions: {
        // 参阅：https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // 参阅：https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      cardOptions: {
        // 反应性
        // 当处理选项时要注意 Vue 2 的反应性限制
        value: {
          postalCode: ''
        },
      },
      visbleDialog:this.showDialog,
      payForm:{
        country:'',
        countryCode:'',
        state:'',
        stateCode:'',
        city:'',
        address:'',
        zip:'',
        email:'',
        firstName:'',
        lastName:'',
        year:'',
        month:'',
        cvv:'',
        ip:'',
        cardNo:'',
        orderId:'',
        phone:'',
        token:'',
        shopName:'',
      },
      payRules:{
        country: [ { required: true, message: this.$t('key51'), trigger: 'change' }],
        state: [ { required: false, message: this.$t('key52'), trigger: 'change' }],
        city: [  { required: true, message: this.$t('key53'), trigger: 'blur' } ],
        address : [  { required: true, message: this.$t('key54'), trigger: 'blur' } ],
        zip: [  { required: true, message: this.$t('key55'), trigger: 'blur' } ],
        email: [  { required: true, message: this.$t('key56'), trigger: 'blur' } ],
        phone:[  { required: true, message: this.$t('key57'), trigger: 'blur' } ],
        firstName: [  { required: true, message: this.$t('key58'), trigger: 'blur' } ],
        lastName: [  { required: true, message: this.$t('key59'), trigger: 'blur' } ],
        year: [  { required: true, message: this.$t('key60'), trigger: 'change' } ],
        month: [  { required: true, message: this.$t('key61'), trigger: 'change' } ],
        cvv: [  { required: true, message: this.$t('key62'), trigger: 'blur' } ],
        cardNo: [  { required: true, message: this.$t('key63'), trigger: 'blur' }, { min: 13, max: 19, message: 'Length of 13 to 19', trigger: 'change' } ],
      },
      yearOptions:[
            {value:'2024',label:'2024'},{value:'2025',label:'2025'},{value:'2026',label:'2026'},{value:'2027',label:'2027'},{value:'2028',label:'2028'},
            {value:'2029',label:'2029'},{value:'2030',label:'2030'},{value:'2031',label:'2031'},{value:'2032',label:'2032'},{value:'2033',label:'2033'},
        ],
      monthOptions:[
          {value:'01',label:'01'},{value:'02',label:'02'},{value:'03',label:'03'},{value:'04',label:'04'},{value:'05',label:'05'},
          {value:'06',label:'06'},{value:'07',label:'07'},{value:'08',label:'08'},{value:'09',label:'09'},{value:'10',label:'10'},
          {value:'11',label:'11'},{value:'12',label:'12'}
      ],
      countryOptions: [],
      stateOptions:[],
    }
  },
  created(){
    this.getCountryOptions()
    this.getIp()
    if(this.channelTag=='STRIPE_PAY'){
      this.getScript()
    }
   this.payForm.shopName = this.shopName
  
  },
  methods:{
    async getIp () {
      try {
        let res = await axios.get('https://ipv4.icanhazip.com/')
        this.payForm.ip = res.data.replace(/[\n\t\s]+/g, '');
        console.log( this.payForm.ip,'公网ip')
        return this.payForm.ip
      } catch (err) {
        console.log('errerrerr',err)
          setTimeout(() => {
          this.getIp()
          }, 2000)
      }
    },
    getCountryOptions(){
      let data = global.data.country
      data.map(v=>{
          v.value = v.iso_code_2
          v.label = v.name
          return v
      })
      this.countryOptions=data
    },
    getRegion(e){
      console.log('confirm',e,this.payForm.country)
      let data = this.countryOptions
      this.payForm.state = ''
      this.payForm.stateCode = ''
      data.map(v=>{
        if(e==v.iso_code_2){
          let zone =v.zone
          console.log('zone',zone)
          this.stateOptions=zone
        } 
        return v
      })
    },
    toPayment(){
      if(this.channelTag =='STRIPE_PAY'){
        let _this = this
          this.stripe2.createToken(this.cardNumberElement).then(function(result){
          
          if (result.error) {
              // 处理错误
              console.log('result,result',result)
              _this.$message.error(result.error.message)
             
            } else {
              // Token 创建成功，将其传递给后端代码
              var token = result.token.id;
              _this.payForm.token = token
              _this.payment()
              console.log(token,'token')
              
          }
        })
        return 
      }
     
    
      
      this.$refs['paymentForm'].validate((valid) => {
        if (valid) {
            console.log('success',this.payForm,this.orderId)
            this.payment()
            // this.$emit('upPayForm',this.payForm)
        } else {
          
        }
      })
    },
    payment(){
      let _this = this
      let payForm = this.payForm
      let orderNo = this.orderId
      let cardNo=payForm.cardNo
      let cardSecurityCode = payForm.cvv
      let cardExpireMonth = payForm.month
      let cardExpireYear = payForm.year
      let billFirstName = payForm.firstName
      let billLastName = payForm.lastName
      let ip = payForm.ip
      // let ip = '45.88.42.177'
      // let ip = '192.168.1.1'
      let billAddress = payForm.address
      let billCity = payForm.city
      let billState = payForm.state?payForm.state:payForm.country
      let billCountry = payForm.country
      let billZip = payForm.zip
      let billPhone = payForm.phone
      let email = payForm.email
      let shipFirstName = payForm.firstName
      let shipLastName = payForm.lastName
      let shipAddress = payForm.address
      let shipCity = payForm.city
      let shipState =  payForm.state?payForm.state:payForm.country
      let shipCountry = payForm.country
      let shipZip = payForm.zip
      let shipPhone = payForm.phone
      let token = payForm.token
      let shopName = payForm.shopName
      _this.$axiosApi.pay({orderNo,cardNo,cardSecurityCode,cardExpireMonth,cardExpireYear,billFirstName,billLastName,ip,billAddress,billCity,billState,billCountry,billZip,billPhone,email,shipFirstName,shipLastName,shipAddress,shipCity,shipState,shipCountry,shipZip,shipPhone,token,shopName}).then(res=>{
          if(res.code == 200){
            _this.closeDialog()
              if(res.data.respCode == 10000){
                  console.log('respCode 10000')
                  _this.$message.success(res.data.respMessage)
                  setTimeout(()=>{
                    if(_this.channelTag =='STRIPE_PAY'){
                      window.open(res.data.billInfo)
                      return
                    }
                    let lang = localStorage.getItem('lang')
                    
                      _this.$router.push('/order/'+lang)
                  },1500)
              }
              else if(res.data.respCode != 10000){
                  console.log('respCode !10000')
                  _this.$message.error(res.data.respMessage)
                  if(res.data.respCode == 10002){
                      if (res.data.payUrl) {
                          console.log('res.data.payUrl',res.data.payUrl)
                          window.open(res.data.payUrl)
                          return
                          // location.href = res.data.data.payUrl
                          window.localStorage.removeItem('callbackHTML')
                          window.localStorage.setItem('callbackHTML', res.data.payUrl)
                          // 读取本地保存的html数据，使用新窗口打开
                          var newWin = window.open(res.data.payUrl)
                          newWin.document.write(localStorage.getItem('callbackHTML'))
                          // 关闭输出流
                          newWin.document.close()
                      }
                  }
              }
              
              
              
          }else{
            _this.closeDialog()
          }
      })
    },
    handleToken(token) {
      // 处理生成的 token
      console.log(token,'tokentokentoken');
    },
    closeDialog(){
      console.log('closeDialog',false)
      this.$emit('closeDialog',false)
    },
    async getScript(){
      
     let lang = localStorage.getItem('lang')
      if(Stripe){
        // 创建Elements实例
        // let stripe2 = Stripe('pk_live_51QRl2uKGPnL9JpEdBFbE9hhc3tO9NGwDD0qmWMq1HI7Pb4opME7hH1WbY6EVvqScoNgOIbKWh5xGqBBB4vRLZa5500NOtmxXSy');
        const stripe2 = await Stripe(this.stripeKey,{locale:lang});
        const elements = stripe2.elements();
        let cardNumberElement = elements.create('cardNumber');
        let cardExpiryElement = elements.create('cardExpiry');
        let cardCvcElement = elements.create('cardCvc');
        cardNumberElement.mount('#card-number');
        cardExpiryElement.mount('#card-expiry');
        cardCvcElement.mount('#card-cvc');
        this.cardNumberElement = cardNumberElement
        this.cardExpiryElement = cardExpiryElement
        this.cardCvcElement = cardCvcElement

        this.stripe2 = stripe2
        let form = document.getElementById('payment-form');
        this.cardNumberElement.addEventListener('change', (event) => {
          
        if (event.error) {
          console.log('支付元素出现错误：', );
        } else {
          console.log('支付元素状态正常',event);
        }
      });
        form.addEventListener('submit', function(event) {
    
        event.preventDefault();
        stripe2.createToken(cardNumberElement).then(function(result) {
            if (result.error) {
                // 处理错误
                console.log('result,result',result.error.message)
                alert(result.error.message);
            } else {
                // Token 创建成功，将其传递给后端代码
                var token = result.token.id;
                console.log(token)
                
            }
         });
       });
       return
      }else{
        setTimeout(()=>{
          this.getScript()
        },1000)
      }
    }
  },
  mounted(){
    
  }
}
</script>

<style lang="scss" scoped>
.dialog{ 
position: fixed;
// margin-top:100px;
left:0;
top:0;
right:0;
bottom:0;
background:#0000008f;
z-index: 999;
.dialog_container{
  padding:16px 72px;
  width: 704px;
  max-width: 100%;
  margin:0 auto;
  position: relative;
  // top:5%;
  border-radius: 6px;
  .dialog_thumb{
    background:#fff;
    border-radius: 6px;
    overflow: hidden;
  }
  .card_label{
    font-size: 14px;
    color:#606266;
  }
  .card_value{
    font-size: 12px;
    padding: 15px;
    // height: 46px;
    border-radius: 4px;
    border:1px solid #e8e9ed;
    margin:6px 0 12px;
    background: #eff2f5;
    border: 1px solid #eff2f5;
    color:#eff2f5;
    line-height: 46px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    &:hover{
     border:1px solid #ffd633 !important
    }
  }
  .dialog_group{
    border-radius: 6px;
    text-align: left;
    width: 100%;
    // padding: 0 24px 24px;
    .dialog_title{
      font-size: 20px;
      line-height: 40px;
      background: #f7f7f7;
      color: #282828;
      margin-bottom: 16px;
      text-align: center;
      font-weight: 600;
    }
  
    .dialog_content{
        position: relative;
       padding:0 24px;
      .input_thumb{
        grid-column-gap:8px;
        .input_group{
          margin-bottom:10px;
        }
      } 
      /deep/.el-form-item__label{
        line-height: 20px;
        padding-bottom:8px;
      }
       

    }
     .dialog_text{
        font-size: 12px;
        line-height: 20px;
        margin-top: 12px;
        color:#77808c;
        text-align: center;
        .link_tab{
            cursor: pointer;
            // text-decoration: underline;
            &:hover{
                text-decoration: underline;
            }
            // text-decoration-color: transparent;
        }
        .link_line{
            margin:0px 8px;
            color:#e8e9ed;
        }
        
        
      }
      
  }
  .pay_tips{
    font-size: 14px;
    line-height: 20px;
    padding: 12px 16px;
    flex: 0 0 auto;
    color: #ff9500;
    background-color: rgba(255, 149, 0, .06);
    position: relative
  }
  .btn_close{
    background:#fff;
    color:#0f0f0f;
    font-size: 24px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: absolute;
    top:16px;
    right:10px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    &:hover{
      opacity: 0.7;
    }
  }
}

}
/deep/.el-input__inner{
    background:#eff2f5;
    height: 46px;
    border:1px solid #eff2f5;
    box-sizing: border-box;
    &:focus{
      border:1px solid #ffd633!important;
    }
    &:hover{
      border:1px solid #ffd633!important;
    }
}
/deep/.el-select{
  width: 100%;
}
.dialog_btn{
  padding:16px;
    >div{
      width: 100%;
    height: 48px;
    font-size: 16px;
    line-height: 48px;
    font-weight: 700;
    cursor: pointer;
    background:#ffcb2e;
    border-radius: 6px;
    text-align: center;
   
    }
    &:hover{
        opacity: 0.7;
    }
}
@media screen and (max-width:768px) {
  .dialog_container{
    width:100%;
    top:0!important;
    left:0;
    right:0;
    bottom:0;
    padding:0!important;
    height: 100%;
   .dialog_thumb{
    height: 100%;
    overflow-y: scroll !important;
    border-radius: 0 !important;
    .dialog_title{
      position: sticky;
      top:0;
      height: 80px;
      z-index:1000;
      box-shadow:  0px 0px 4px -1px rgba(0, 0, 0, .1), 0px 0px 5px 0px rgba(0, 0, 0, .08), 0px 0px 10px 0px rgba(0, 0, 0, .05);
    }
    .pay_bottom{
      position: sticky;
      bottom:0;
      z-index: 1000;
      background: #fff;
    }
    // .pay_tips{
    //   position: sticky;
    //   bottom:80px;
    //   z-index: 1000;
    // }
    // .dialog_btn{
      
    // }
   }
   
    .btn_close{
      left:16px;
      z-index: 1001;
    }
   
  }
}

</style>